import React, {useEffect, useState} from 'react'
import decorateWithOrdersContext from "../../hoc/OdersHOC";
import Styles from './CountryCodePicker.module.scss'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SearchIcon from './images/search.svg';
import {Divider, useMediaQuery, useTheme} from "@material-ui/core";
import CloseIcon from '../SideDrawer/Images/close.svg'

const Items = ({flag, code, name, onClick}) => {
    return (
        <div className={Styles.CountryCodeItem} onClick={onClick}>
            <img src={flag}/>
            <aside>
                <h6>+{code}</h6>
                <p>{name}</p>
            </aside>
        </div>
    )
}
const CountryCodePicker = ({ordersContext, onClose, open, onCountrySelected}) => {

    const data = ordersContext.dialingCodes
    const [countryData, setCountryData] = useState([])
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    useEffect(()=>{
        setCountryData(data)
    },[])

    const search =( query ) => {
        if (query.length === 0){
            setCountryData(data)
        }else{
            const res = data.filter(item => {
                return (item.name.toLowerCase().includes(query.toLowerCase()))
            })
            setCountryData(res)
        }
    }
    return (
        <div className={Styles.CountryCodePicker}>
            <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
                <DialogTitle className={Styles.CountryCodePickerHeader}>
                    <h4>Choose your country</h4>
                    <div className={`${Styles['UserInput-Container']} Input`}>
                        <input
                            type="search"
                            className={Styles['UserInput']}
                            placeholder="Search"
                            onChange={event => search(event.target.value)}
                        />
                        <img src={SearchIcon} className={Styles['UserInput-Icon']}/>
                    </div>
                    <Divider/>
                </DialogTitle>
                <img className={Styles.CloseButton} src={CloseIcon} onClick={onClose}/>
                <DialogContent className={Styles.CountryCodePicker}>

                    {countryData.length > 0? <div className={Styles.CountryCodeItems}>
                        {countryData.map(item => {
                            return <Items
                                key={item["country_code"]}
                                flag={item.flag}
                                code={item["dialing_code"]}
                                name={item.name}
                                onClick={() => {
                                    onClose()
                                    onCountrySelected(item.flag, `+${item["dialing_code"]}`)
                                }
                                }
                            />
                        })}
                    </div>:
                        <section><p style={{textAlign:"center"}}>No search results found</p></section>
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default decorateWithOrdersContext(CountryCodePicker)
