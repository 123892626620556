import * as React from 'react'
import ReactTooltip from "react-tooltip";

import Shave from '@littlestores/common/components/Shave'
import AddWidget from '@littlestores/storekeeper-microsite/src/components/AddWidget'

import decorateWithOrdersContext from '../../../../hoc/OdersHOC'

import styles from './CheckoutCard.module.scss'

function CheckoutCard({imgUrl, title, outOfStock, quantity, price, id, ordersContext, last, ...props}) {
  const updateOrder = (units) => {
    ordersContext.updateOrders({ ...props, imgUrl, title, outOfStock, quantity, price, id, units })
  }

  const units = ordersContext.orders.find(orderItem => orderItem.id === id)?.units

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', padding: '12px 0', borderBottom: last ? "none" : '2px solid #eceff1'}}>
        <div style={{padding:" 8px 12px"}}>
          <div className={styles['Title']} data-tip={title}><Shave maxHeight={50}>{title}</Shave></div>
          <ReactTooltip place="top" effect="solid"/>
          <span className={styles['Price--small']}>{`₹ ${price}`}</span>
          { quantity && <div className={styles['Quantity']}>{`Quantity: ${quantity}`}</div> }
        </div>
        <div style={{marginRight:"12px",display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <AddWidget onChange={updateOrder} disabled={outOfStock} units={units}/>
          <div className={styles['Price']}>{price * units}</div>
        </div>
      </div>
    </>
  )
}

export default decorateWithOrdersContext(CheckoutCard)
