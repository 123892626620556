import RestApiService from "@littlestores/common/services/RestApiService";
import urls from '../constants/urls'

const dataMap = {
  order: {
    endpoint: urls.ORDER,
    bodyAsForm: false,
    model: (model) => model.data.data,
  },
}

class OrderService extends RestApiService {
  constructor() {
    super(dataMap)
  }

  order = (data) => this.post('order', {}, data)
}

export default OrderService
