import * as React from 'react'
import Alert from 'react-s-alert'
import {navigate} from 'gatsby'
import qs from 'querystring'

import Button from '@littlestores/common/components/Button'
import Container from '@littlestores/common/components/Container'
import Layout from '@littlestores/common/components/Layout'
import Title from '@littlestores/common/components/Title'
import Sticky from '@littlestores/common/components/Sticky/Sticky'

// import Card from '../Card'
import CheckoutCard from './components/CheckoutCard'

import {HOME, PLACED} from '../../constants/paths'
import decorateWithOrdersContext from '../../hoc/OdersHOC'

import OrderService from '../../services/OrderService'

import BackIcon from './images/back.svg'
import CloseIcon from './images/close.svg'
import UserIcon from './images/person.svg'
import WhatsAppIcon from './images/whatsapp.svg'
import AddressIcon from './images/address.svg'
import AddToCart from '../../images/add-to-cart.svg'
import AddressLineIcon from './images/addLine.svg'

import styles from './Checkout.module.scss'
import {useEffect, useState} from "react";
import CountryCodePicker from "../CountryCodePicker/CountryCodePicker";

const orderService = new OrderService()

function Checkout({ordersContext}) {
    const [showModal, setShowModal] = React.useState(false)
    const [name, setName] = React.useState(ordersContext.getUserName())
    const [phoneNumber, setPhoneNumber] = React.useState(ordersContext.getUserPhoneNumber())
    const [addressLine, setAddressLine] = React.useState(ordersContext.getUserAddress()?.line)
    const [addressPin, setAddressPin] = React.useState(ordersContext.getUserAddress()?.pincode)
    const [addressLand, setAddressLand] = React.useState(ordersContext.getUserAddress()?.landmark)
    const [phoneCode, setPhoneCode] = React.useState('+91')
    const [flag, setFlag] = React.useState('')
    const [orderButtonDisabled, setOrderButtonDisabled] = React.useState(false)
    const [showCountryPicker, setShowCountryPicker] = useState(false)
    const nameRef = React.useRef(null)
    const phoneNumberRef = React.useRef(null)
    const addressLineRef = React.useRef(null)
    const addressPinRef = React.useRef(null)
    const addressLandMark = React.useRef(null)

    const storeDeliveryOption = ordersContext.deliveryOption

    //setting default flag to INDIAN flag
    useEffect(() => {
        setFlag("https://restcountries.eu/data/ind.svg")
    }, [])
    let buttonContent = 'Submit'

    const orders = ordersContext
        .orders
        .filter(orderItem => orderItem.units > 0 && !orderItem.outOfStock)

    const total = orders.reduce((sum, orderItem) => sum + orderItem.units * orderItem.price, 0)
    const noOrders = orders.length === 0 || ordersContext.stock.length === 0

    if (ordersContext.stock.length === 0) {
        typeof window === 'object' && navigate(HOME)
    }

    const onUserInformationSubmit = () => {
        setOrderButtonDisabled(true)
        ordersContext.setUserName(name)
        ordersContext.setUserPhoneNumber(phoneNumber)
        const address = {
            pincode: String(addressPin) || '',
            landmark: addressLand || '',
            line: addressLine || '',
        }
        ordersContext.setUserAddress(address)
        orderService.order({
            user: {
                name,
                mobile: String(phoneNumber),
                address
            },
            orders: {
                details: orders.map(_order => ({product: _order.id, quantity: _order.units})),
                notes: 'my note'
            }
        })

            .then(data => {
                    navigate(PLACED + '?' + qs.stringify({
                            orders: JSON.stringify(orders),
                            id: data[0]['id'],
                            merchant: data[0]['mobile_no']
                        }
                    ))
                }
            )
            .then(() => setOrderButtonDisabled(false))
            .catch(() => {
                Alert.error('Saved orders for later. Your order items will remain when you visit next time.')
                setOrderButtonDisabled(false)
            })
    }

    const onCountrySelected = (flag, code) => {
        setFlag(flag)
        setPhoneCode(code)
    }

    if (orderButtonDisabled) {
        buttonContent = (
            <div className={styles['spinner']}>
                <div className={styles['bounce1']}></div>
                <div className={styles['bounce2']}></div>
                <div className={styles['bounce3']}></div>
            </div>
        )
    }

    return (
        <>
            <Layout>
                <div className={styles['Back']} onClick={() => navigate(HOME)}><img src={BackIcon} alt="←"/></div>
                <Title className={styles['Title']}>Checkout</Title>
            </Layout>
            <Layout className={styles.ContainerLayout}>
                <Container className={noOrders ? '' : styles['Container']}>
                    {
                        // orders.map(orderItem => <Card key={orderItem.id} {...orderItem} isCheckout />)
                        ordersContext.stock.length > 0 && orders.map((orderItem, index) => <CheckoutCard
                            key={orderItem.id} {...orderItem} last={index === orders.length - 1}/>)
                    }
                    {
                        noOrders && (
                            <>
                                <h3>Add items in your cart</h3>
                                <img src={AddToCart} width="100%"/>
                            </>
                        )
                    }
                </Container>
            </Layout>
            {
                !noOrders &&
                <Sticky bottom="0px" className={styles['Total']}>
                    <Layout className={styles.BottomLayout}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div className={styles['ToPay']}>To Pay &nbsp;&nbsp;₹{Number(total).toFixed(2)}</div>
                            <span><Button className={styles['Order--button']}
                                          onClick={() => setShowModal(true)}>Checkout</Button></span>
                        </div>
                    </Layout>
                </Sticky>
            }

            {
                showModal && (
                    <>
                        <div className={styles['overlay']} onClick={() => setShowModal(false)}/>
                        <Sticky bottom="0px" className={styles['Order--overlay']}>
                            <Layout>
                                <div style={{
                                    position: 'absolute',
                                    top: '2px',
                                    right: '4px',
                                    padding: "8px"
                                }}>
                                    <img src={CloseIcon} alt="X" onClick={() => setShowModal(false)}
                                         style={{cursor: 'pointer'}}/>
                                </div>
                                <div className={styles['UserDetails']}>
                                    <div className={`${styles['UserInput-Container']} Input`}>
                                        <input type="text" className={styles['UserInput']} placeholder="Name"
                                               value={name} onChange={(evt) => setName(evt.target.value)}
                                               ref={nameRef}/>
                                        <img src={UserIcon} className={styles['UserInput-Icon']}
                                             onClick={() => nameRef.current.focus()}/>
                                    </div>
                                    <div className={`${styles['UserInput-Container']} Input`}>
                                        <div className={styles.UserInputLeading}
                                             onClick={() => setShowCountryPicker(true)}>
                                            <img src={flag}/>
                                            <p>{phoneCode} </p>
                                        </div>
                                        <input style={{paddingLeft: "120px"}} type="tel" className={styles['UserInput']}
                                               placeholder="WhatsApp Number" value={phoneNumber}
                                               onChange={(evt) => setPhoneNumber(evt.target.value)}
                                               ref={phoneNumberRef}/>
                                        <img src={WhatsAppIcon} className={styles['UserInput-Icon']}
                                             onClick={() => phoneNumberRef.current.focus()}/>
                                    </div>
                                    {storeDeliveryOption === 'both' &&
                                    <div className={`${styles['UserInput-Container']} Input`}>
                                        <input type="address" className={styles['UserInput']}
                                               placeholder="Address Line"
                                               value={addressLine}
                                               onChange={(evt) => setAddressLine(evt.target.value)}
                                               ref={addressLineRef}/>
                                        <img src={AddressLineIcon} className={styles['UserInput-Icon']}
                                             onClick={() => addressLineRef.current.focus()}/>

                                    </div>}

                                    {storeDeliveryOption === 'both' &&
                                    <div style={{display: 'flex', marginBottom: '20px'}}>
                                        <div style={{flex: '1', marginRight: "10px"}}
                                             className={`${styles['UserInput-Container']} Input`}>
                                            <input type="tel" className={styles['UserInput']} placeholder="Pin code"
                                                   value={addressPin}
                                                   onChange={(evt) => setAddressPin(evt.target.value)}
                                                   ref={addressPinRef}/>
                                            
                                        </div>
                                        <div style={{flex: '1'}} className={`${styles['UserInput-Container']} Input`}>
                                            <input type="text" className={styles['UserInput']} placeholder="Landmark"
                                                   value={addressLand}
                                                   onChange={(evt) => setAddressLand(evt.target.value)}
                                                   ref={addressLandMark}/>
                                        </div>
                                    </div>
                                    }
                                    <Button className={styles.SubmitButton} disabled={orderButtonDisabled}
                                            onClick={onUserInformationSubmit}>{buttonContent}</Button>
                                </div>
                            </Layout>
                        </Sticky>
                    </>
                )
            }
            {
                showCountryPicker &&
                <CountryCodePicker
                    open={showCountryPicker}
                    onClose={() => setShowCountryPicker(false)}
                    onCountrySelected={onCountrySelected}
                />

            }
        </>
    )
}

export default decorateWithOrdersContext(Checkout)
